import Vue from "vue";
import VueRouter from "vue-router";

import cookies from "@/helpers/cookies";
import store from "@/store";

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		redirect: to => {
			const role = store.getters["auth/GET_USER_ROLE_VUEX"];
			const base_role = store.getters["auth/GET_USER_ROLE"];
			const companyType = store.getters["auth/GET_COMPANY_TYPE"];
			console.log(role);
			if (!role || role.includes("employee") || role.includes("user")) {
				return {
					path: "/logout",
					query: { role: "declined" }
				};
			} else if (role === "administrator") {
				return "/qr";
			} else if (role === "dispatcher") {
				if(companyType !== 'sc' && base_role !== 'ksk_dispatcher') {
					return "/dispatcher/home";
				} else {
					return "/dispatcher/requisitions";
				}
			} else {
				return "/requisitions";
			}
		},
		name: "Home",
		component: () => import("@/layouts/MainLayout.vue"),
		children: [
			{
				// * NOTIFICATIONS (УВЕДОМЛЕНИЯ)
				path: "notifications",
				component: {
					render(c) {
						return c("router-view");
					},
				},
				children: [
					{
						path: "",
						name: "Notifications",
						component: () => import("@/views/NotificationsTable.vue"),
						meta: {
							title: "Уведомления",
							auth: true,
							permission: ["ksk", "ksk_manager"],
						},
					},
				],
			},
			{
				// * USER (ПРОФИЛЬ СОТРУДНИКА)
				path: "user",
				component: {
					render(c) {
						return c("router-view");
					},
				},
				children: [
					{
						path: "",
						name: "Profile",
						component: () => import("@/views/Profile.vue"),
						meta: {
							title: "Профиль сотрудника",
							auth: true,
							permission: [
								"dispatcher",
								"ksk_dispatcher",
								"ksk",
								"ksk_manager",
								"ksk_employee",
								"administrator",
							],
						},
					},
				],
			},
			{
				// * REQUISITIONS (ЗАЯВКИ)
				path: "requisitions",
				component: {
					render(c) {
						return c("router-view");
					},
				},
				children: [
					{
						path: "",
						name: "Requisitions",
						component: () => import("@/views/RequisitionsTable.vue"),
						meta: {
							title: "Заявки",
							auth: true,
							permission: [
								"ksk",
								"ksk_manager",
								"ksk_employee",
								"administrator",
							],
						},
					},
					{
						path: ":id",
						name: "RequisitionsItem",
						component: () => import("@/views/RequisitionsItem.vue"),
						meta: {
							title: "Индивидуальная заявка",
							auth: true,
							permission: [
								"ksk",
								"ksk_manager",
								"ksk_employee",
								"dispatcher",
								"ksk_dispatcher",
								"administrator",
							],
						},
					},
				],
			},
			{
				// * EMPLOYEE (СОТРУДНИКИ)
				path: "employee",
				component: {
					render(c) {
						return c("router-view");
					},
				},
				children: [
					{
						path: "",
						name: "Employee",
						component: () => import("@/views/EmployeeTable.vue"),
						meta: {
							title: "Сотрудники",
							auth: true,
							permission: ["ksk", "ksk_manager", "administrator"],
						},
					},
					{
						path: ":id",
						name: "EmployeeItem",
						component: () => import("@/views/EmployeeItem.vue"),
						meta: {
							title: "Информация о сотруднике",
							auth: true,
							permission: ["ksk", "ksk_manager", "ksk_employee", "administrator"],
						},
					},
				],
			},
			{
				// * ЖИТЕЛИ (TENANTS)
				path: "tenants",
				component: {
					render(c) {
						return c("router-view");
					},
				},
				children: [
					{
						path: "",
						name: "Tenants",
						component: () => import("@/views/TenantsTable.vue"),
						meta: {
							title: "Жители",
							auth: true,
							permission: ["ksk", "ksk_manager", "administrator"],
						},
					},
					{
						path: ":id",
						name: "TenantsItem",
						component: () => import("@/views/TenantsItem.vue"),
						meta: {
							title: "Информация о жителе",
							auth: true,
							permission: [
								"ksk",
								"ksk_manager",
								"ksk_employee",
								"administrator",
							],
						},
					},
				],
			},
			{
				// * ДОМА (HOUSES)
				path: "houses",
				component: {
					render(c) {
						return c("router-view");
					},
				},
				children: [
					{
						path: "",
						name: "Houses",
						component: () => import("@/views/HousesTable.vue"),
						meta: {
							title: "Дома",
							auth: true,
							permission: ["ksk", "ksk_manager", "administrator"],
						},
					},
					{
						path: ":id",
						name: "HousesItem",
						component: () => import("@/views/HousesItem.vue"),
						meta: {
							title: "Информация о доме",
							auth: true,
							permission: ["ksk", "ksk_manager", "ksk_employee"],
						},
					},
				],
			},
			{
				// * QR
				path: "qr",
				component: {
					render(c) {
						return c("router-view");
					},
				},
				children: [
					{
						path: "",
						redirect: "/qr/porch",
						name: "QR",
						component: () => import("@/views/QrTable.vue"),
						meta: {
							title: "QR",
							auth: true,
							permission: ["ksk", "ksk_manager", "administrator"],
						},
						children: [
							{
								path: "gates",
								name: "QrGates",
								component: () => import("@/views/QrTableGates.vue"),
								meta: {
									title: "QR Калитки",
									qrType: "gates",
									auth: true,
									permission: ["ksk", "ksk_manager", "administrator"],
								},
							},
							{
								path: "gates-logs",
								name: "QrGatesLogs",
								component: () => import("@/views/QrTableGatesLogs.vue"),
								meta: {
									title: "QR Калитки (логи)",
									qrType: "gate",
									auth: true,
									permission: ["ksk", "ksk_manager", "administrator"],
								},
							},
							{
								path: "porch",
								name: "QrPorch",
								component: () => import("@/views/QrTablePorch.vue"),
								meta: {
									title: "QR Домофоны",
									auth: true,
									permission: ["ksk", "ksk_manager", "administrator"],
								},
							},
							{
								path: "barrier",
								name: "QrBarrier",
								component: () => import("@/views/QrTableBarrier.vue"),
								meta: {
									title: "QR Шлагбаумы",
									qrType: "entry",
									auth: true,
									permission: ["ksk", "ksk_manager", "administrator"],
								},
							},
							{
								path: "parkings",
								name: "QrParkings",
								component: () => import("@/views/QrTableBarrier.vue"),
								meta: {
									title: "QR Паркинги",
									qrType: "parking",
									auth: true,
									permission: ["ksk", "ksk_manager", "administrator"],
								},
							},
							{
								path: "entries",
								name: "QrEntries",
								component: () => import("@/views/QrTableEntries.vue"),
								meta: {
									title: "QR Въезды",
									qrType: "entry",
									auth: true,
									permission: ["ksk", "ksk_manager", "administrator"],
								},
							},
							{
								path: "whitelist",
								name: "QrWhitelist",
								component: () => import("@/views/QrTableWhitelist.vue"),
								meta: {
									title: "QR Белые листы",
									auth: true,
									permission: ["ksk", "ksk_manager", "administrator"],
								},
							},
						],
					},
				],
			},
			{
				// * QR Next
				path: "qr-next",
				component: {
					render(c) {
						return c("router-view");
					},
				},
				children: [
					{
						path: "",
						redirect: "/qr-next/groups",
						name: "QrNextTable",
						component: () => import("@/views/QrNextTable.vue"),
						meta: {
							title: "QR",
							auth: true,
							permission: ["ksk", "ksk_manager", "administrator"],
						},
						children: [
							{
								path: "groups",
								name: "QrNextGroups",
								component: () => import("@/views/QrNextGroups.vue"),
								meta: {
									title: "QR Группы",
									qrType: "entry",
									auth: true,
									permission: ["ksk", "ksk_manager", "administrator"],
								},
							},
							{
								path: "logs",
								name: "QrNextLogs",
								component: () => import("@/views/QrNextLogs.vue"),
								meta: {
									title: "QR Логи",
									qrType: "gate",
									auth: true,
									permission: ["ksk", "ksk_manager", "administrator"],
								},
							},
							{
								path: "whitelist",
								name: "QrNextWhitelist",
								component: () => import("@/views/QrNextWhitelist.vue"),
								meta: {
									title: "QR Белые листы",
									auth: true,
									permission: ["ksk", "ksk_manager", "administrator"],
								},
							},
							{
								path: "devices",
								name: "QrNextDevices",
								component: () => import("@/views/QrNextDevices.vue"),
								meta: {
									title: "QR устройства",
									auth: true,
									permission: ["ksk", "ksk_manager", "administrator"],
								},
							},
						],
					},
				],
			},
			{
				// * VOTE (ГОЛОСОВАНИЕ)
				path: "vote",
				component: {
					render(c) {
						return c("router-view");
					},
				},
				children: [
					{
						path: "",
						name: "Vote",
						component: () => import("@/views/VoteTable.vue"),
						meta: {
							title: "Голосования",
							auth: true,
							permission: ["ksk", "ksk_manager", "administrator"],
						},
					},
					{
						path: ":id",
						name: "VoteItem",
						component: () => import("@/views/VoteItem.vue"),
						meta: {
							title: "Голосование",
							auth: true,
							permission: ["ksk", "ksk_manager", "administrator"],
						},
					},
				],
			},
			{
				// * VOTE NEW (ГОЛОСОВАНИЕ)
				path: "vote-new",
				component: {
					render(c) {
						return c("router-view");
					},
				},
				children: [
					{
						path: "",
						name: "VoteNew",
						component: () => import("@/views/VoteNewTable.vue"),
						meta: {
							title: "Голосования",
							auth: true,
							permission: ["ksk", "ksk_manager", "administrator"],
						},
					},
					{
						path: ":id",
						name: "VoteItemNew",
						component: () => import("@/views/VoteItemNew.vue"),
						meta: {
							title: "Голосование",
							auth: true,
							permission: ["ksk", "ksk_manager", "administrator"],
						},
					},
				],
			},
			{
				// * BI ANALYTICS
				path: "bi-analytics",
				component: {
					render(c) {
						return c("router-view");
					},
				},
				children: [
					{
						path: "",
						component: () => import("@/views/AnalyticsPowerBi.vue"),
						meta: {
							title: "Аналитика",
							auth: true,
							permission: ["ksk", "ksk_manager", "administrator"],
						},
					}
				]
			},
			{
				// * ANALYTIC (АНАЛИТИКА)
				path: "bi-analytics",
				component: {
					render(c) {
						return c("router-view");
					},
				},
				children: [
					{
						path: "",
						component: () => import("@/views/AnalitycsPowerBi.vue"),
						meta: {
							title: "Аналитика",
							auth: true,
							permission: ["ksk", "ksk_manager", "administrator"],
						},
					}
				]
			},
			{
				// * ANALYTIC (АНАЛИТИКА)
				path: "analytic",
				component: {
					render(c) {
						return c("router-view");
					},
				},
				children: [
					{
						path: "",
						component: () => import("@/views/Analytic.vue"),
						meta: {
							title: "Аналитика",
							auth: true,
							permission: ["ksk", "ksk_manager", "administrator"],
						},
						children: [
							{
								path: "",
								name: "ReportSummary",
								component: () => import("@/views/AnalyticReport.vue"),
								meta: {
									title: "Сводный отчет",
									auth: true,
									permission: ["ksk", "ksk_manager", "administrator"],
								},
							},
							{
								path: "requisitions",
								name: "AnalyticRequisitions",
								component: () => import("@/views/AnalyticRequisitions.vue"),
								meta: {
									title: "Аналитика заявок",
									auth: true,
									permission: ["ksk", "ksk_manager", "administrator"],
								},
							},
							{
								path: "tenants",
								name: "AnalyticTenants",
								component: () => import("@/views/AnalyticTenants.vue"),
								meta: {
									title: "Аналитика жителей",
									auth: true,
									permission: ["ksk", "ksk_manager", "administrator"],
								},
							},
							{
								path: "employee",
								name: "AnalyticEmployee",
								component: () => import("@/views/AnalyticEmployee.vue"),
								meta: {
									title: "Аналитика сотрудников",
									auth: true,
									permission: ["ksk", "ksk_manager", "administrator"],
								},
							},
						],
					},
				],
			},
			// * FAQ
			{
				path: "faq",
				component: {
					render(c) {
						return c("router-view");
					},
				},
				children: [
					{
						// Home page
						path: "",
						component: () => import("@/views/Faq.vue"),
						meta: {
							title: "Часто задаваемые вопросы",
							auth: true,
							permission: ["administrator"],
						},
					},
				],
			},
			// * POSTS
			{
				path: "posts",
				component: {
					render(c) {
						return c("router-view");
					},
				},
				children: [
					{
						// Home page
						path: "",
						component: () => import("@/views/Posts.vue"),
						meta: {
							title: "Посты",
							auth: true,
							permission: ["administrator"],
						},
					},
				],
			},
			// * УК
			{
				path: "ksk",
				component: {
					render(c) {
						return c("router-view");
					},
				},
				children: [
					{
						// Home page
						path: "",
						component: () => import("@/views/Ksk.vue"),
						meta: {
							title: "УК",
							auth: true,
							permission: ["administrator"],
						},
					},
				],
			},
			// * АНКЕТА
			{
				path: "questionnaire",
				component: {
					render(c) {
						return c("router-view");
					},
				},
				children: [
					{
						// Home page
						path: "",
						component: () => import("@/views/Questionnaire.vue"),
						meta: {
							title: "Анкета",
							auth: true,
							permission: ["ksk", "ksk_manager"],
						},
					},
				],
			},
			// * PRICELIST (РАСЦЕНКИ)
			{
				path: "pricelist",
				component: {
					render(c) {
						return c("router-view");
					},
				},
				children: [
					{
						path: "",
						component: () => import("@/views/Pricelist.vue"),
						meta: {
							title: "Расценки",
							auth: true,
							permission: ["ksk", "ksk_manager", "administrator"],
						},
					},
				],
			},
			// * DISPATCHER (ДИСПЕТЧЕР)
			{
				path: "dispatcher",
				redirect: "/dispatcher/home",
				component: {
					render(c) {
						return c("router-view");
					},
				},
				children: [
					{
						// Home page
						path: "home",
						component: () => import("@/views/dispatcher/Home.vue"),
						meta: {
							title: "Главная",
							auth: true,
							permission: ["dispatcher", "ksk_dispatcher"],
						},
					},
					{
						// Requests
						path: "requisitions",
						component: () => import("@/views/RequisitionsTable.vue"),
						meta: {
							title: "Заявки",
							auth: true,
							permission: ["dispatcher", "ksk_dispatcher", "administrator"],
						},
					},
					{
						// * NOTIFICATIONS (УВЕДОМЛЕНИЯ)
						path: "notifications",
						component: {
							render(c) {
								return c("router-view");
							},
						},
						children: [
							{
								path: "",
								name: "NotificationsDispatcher",
								component: () => import("@/views/NotificationsTable.vue"),
								meta: {
									title: "Уведомления",
									auth: true,
									permission: ["dispatcher"],
								},
							},
						],
					},
					{
						// * EMPLOYEE (СОТРУДНИКИ)
						path: "employee",
						component: {
							render(c) {
								return c("router-view");
							},
						},
						children: [
							{
								path: "",
								name: "Employee",
								component: () => import("@/views/EmployeeTable.vue"),
								meta: {
									title: "Сотрудники",
									auth: true,
									permission: ["ksk_dispatcher"],
								},
							},
							{
								path: ":id",
								name: "EmployeeItem",
								component: () => import("@/views/EmployeeItem.vue"),
								meta: {
									title: "Информация о сотруднике",
									auth: true,
									permission: ["ksk_dispatcher"],
								},
							},
						],
					},
					{
						// * ДОМА (HOUSES)
						path: "houses",
						component: {
							render(c) {
								return c("router-view");
							},
						},
						children: [
							{
								path: "",
								name: "Houses",
								component: () => import("@/views/HousesTable.vue"),
								meta: {
									title: "Дома",
									auth: true,
									permission: ["ksk_dispatcher"],
								},
							},
							{
								path: ":id",
								name: "HousesItem",
								component: () => import("@/views/HousesItem.vue"),
								meta: {
									title: "Информация о доме",
									auth: true,
									permission: ["ksk_dispatcher"],
								},
							},
						],
					},
				],
			},
			{
				// * PAGE IN PROGRESS
				path: "/page-in-progress",
				name: "PageInProgress",
				component: () => import("@/views/PageInProgress.vue"),
				meta: { title: "Раздел в разработке", permission: true },
			},
		],
	},
	// {
	// 	// * TESTS
	// 	path: "/tests/kaspi1",
	// 	name: "KASPI",
	// 	component: () => import("@/views/tests/Test1Page.vue"),
	// 	meta: { title: "Test", permission: true },
	// },
	// {
	// 	// * TESTS
	// 	path: "/tests/kaspi2",
	// 	name: "KASPI",
	// 	component: () => import("@/views/tests/Test2Page.vue"),
	// 	meta: { title: "Test", permission: true },
	// },
	// {
	// 	// * TESTS
	// 	path: "/tests/kaspi3",
	// 	name: "KASPI",
	// 	component: () => import("@/views/tests/Test3Page.vue"),
	// 	meta: { title: "Test", permission: true },
	// },
	{
		// * WEBVIEW
		path: "/services",
		name: "Services",
		component: () => import("@/views/Services.vue"),
		meta: { title: "Услуги", permission: true },
	},
	{
		// * WEBVIEW
		path: "/houseacceptance",
		component: {
			render(c) {
				return c("router-view");
			},
		},
		children: [
			{
				path: "",
				name: "HouseAcceptance",
				component: () => import("@/views/HouseAcceptance.vue"),
				meta: { title: "Услуги", permission: true },
			},
			{
				path: ":token",
				name: "HouseAcceptanceItem",
				component: () => import("@/views/HouseAcceptance.vue"),
				meta: { title: "Услуги", permission: true },
			},
		]
	},
	{
		// * AGREEMENTS
		path: "/agreements",
		name: "Agreements",
		component: () => import("@/views/Agreements.vue"),
		meta: { title: "Пользовательское соглашение", permission: true },
	},
	{
		// * REGISTER
		path: "/register",
		name: "Register",
		component: () => import("@/views/Register.vue"),
		meta: { title: "Регистрация", permission: true, notAuth: true },
	},
	{
		// * LOGIN
		path: "/login",
		name: "Login",
		component: () => import("@/views/Login.vue"),
		meta: { title: "Авторизация", permission: true, notAuth: true },
	},
	{
		// * Сброс пароля
		path: "/forgotpassword",
		name: "ForgotPassword",
		component: () => import("@/views/ForgotPassword.vue"),
		meta: { title: "Сброс пароля", permission: true, notAuth: true },
	},
	{
		// * LOGOUT
		path: "/logout",
		name: "Logout",
		component: () => import("@/views/Logout.vue"),
		meta: { title: "Авторизация", permission: true },
	},

	{
		// * 403
		path: "/permission",
		name: "Error403",
		component: () => import("@/views/Error403.vue"),
		meta: { title: "Доступ запрещен", permission: true },
	},
	{
		// * 404
		path: "*",
		name: "Error404",
		component: () => import("@/views/Error404.vue"),
		meta: { title: "Страница не найдена", permission: true },
	},
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
	scrollBehavior(to, from, savedPosition) {
		if (!to.matched.some(t => t.meta.enableScroll === true)) {
			return;
		}
		/**
		 * @desc Timeout, чтобы скролл произошел после прогрузки страницы с таблицами
		 * @note Может не сработать при медленном интернете
		 */
		if (savedPosition) {
			return new Promise((resolve, reject) => {
				setTimeout(() => {
					resolve(savedPosition);
				}, 1000);
			});
		} else {
			return new Promise((resolve, reject) => {
				setTimeout(() => {
					resolve({ x: 0, y: 0 });
				}, 500);
			});
		}
	},
});

// * Route Guard
router.beforeEach(async (to, from, next) => {
	const auth = to.matched.some(record => record.meta.auth);
	const permission = to.meta.permission;
	const token = await cookies.get_token();

	// ? Сравнение роли пользователя с разрешенными ролями роута
	const checkPermission = () => {
		const role = store.getters["auth/GET_USER_ROLE"];
		if (!role) cookies.remove_token();
		return permission !== true ? permission.includes(role) : true;
	};

	if (!token && auth) {
		next("/login");
	} else if (to.meta.notAuth && token) {
		next(from.path);
	} else if (checkPermission()) {
		document.title = to.meta.title;
		next();
	} else {
		next("/permission");
	}
});

export default router;
