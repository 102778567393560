import Vue from "vue";
import { mapGetters } from "vuex";
import { kazToRus, timestampToDdmmyyyy, getFullname } from "@/helpers/helpers";

import store from "@/store";
import instance from "@/api/instance";

const mixin = {
	data() {
		return {
			acceptAllFiles:
				"image/jpg, image/jpeg, image/png, .pdf, .doc, .docx, application/vnd.ms-excel, application/msword, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
			acceptImages: "image/jpg, image/jpeg, image/png",
			acceptDocuments:
				".pdf, .doc, .docx, application/vnd.ms-excel, application/msword, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
		};
	},
	computed: {
		...mapGetters({
			userRole: "auth/GET_USER_ROLE",
		}),
	},
	methods: {
		// * Фильтр для поиска совпадений по адресам
		addressFilter(item, queryText, itemText) {
			itemText = kazToRus(itemText);
			queryText = kazToRus(queryText);

			return queryText.split(" ").every(e => itemText.includes(e));
		},

		/**
		 * * Скачивание фото с сервера
		 * @param showDefault = true if need to show no avatar image
		 * */
		loadImageUrl(url, showDefault = true) {
			if (!url && showDefault) {
				return require("@/assets/user.svg");
			}
			return `${process.env.VUE_APP_API_URL}/${url}`;
		},
		async loadServerImage(avatar, showDefault = true) {
			if (!avatar && showDefault) {
				return require("@/assets/user.svg");
			}
			console.log('urlurlurlurlurlurlurlurlurlurlurl', avatar)
			console.log('this.$api.ksk', this.$api.ksk)
			if(avatar?.id) {
				return this.$api.ksk.load_file(avatar.id)
					.then(response => {
						const blob = new Blob([response], { type: 'application/octet-stream' });
						const blobUrl = URL.createObjectURL(blob);
						return blobUrl;
					})
					.catch(error => {
						console.error('Ошибка при загрузке изображения:', error);
						return require("@/assets/user.svg");
					});
			} else {
				return require("@/assets/user.svg");
			}
			// return `${process.env.VUE_APP_API_URL}/${url}`;
		},

		// * Получение названия дома по ID
		getHouseNameById(id) {
			const houses = store.getters["ksk/GET_HOUSES"];
			const house = houses.find(e => e._id === id);
			return house.apartment_address;
		},

		// * Загрузка изображений через URL
		// TODO: cors
		fileDownloadFromUrl({ url, name }) {
			return instance({
				url,
				method: "get",
				responseType: "arraybuffer",
			})
				.then(res => {
					const url = window.URL.createObjectURL(new Blob([res.data]));
					const link = document.createElement("a");
					link.href = url;
					link.setAttribute("download", name);
					document.body.appendChild(link);
					link.click();
				})
				.catch(err => {
					throw err;
				});
		},

		// * Получение пути Vuex метода(геттер, мутация, действие) динамическим путем
		getVuexMethod(name) {
			return `${this.role}/${name}`;
		},

		timestampToDdmmyyyy,

		getFullname,
	},
};

Vue.mixin(mixin);
