export default function(instance) {
	return {
		add_entrance(data) {
			return instance({
				url: "/v2/ksk/houses/constructor/entrances",
				method: "post",
				data,
			})
				.then(res => res.data)
				.catch(e => {
					throw e;
				});
		},

		edit_entrance({ id, data }) {
			return instance({
				url: `/v2/ksk/houses/constructor/entrances/${id}`,
				method: "post",
				data,
			})
				.then(res => res.data)
				.catch(e => {
					throw e;
				});
		},

		delete_entrance(id) {
			return instance({
				url: `/v2/ksk/houses/constructor/entrances/delete/${id}`,
				method: "post",
			})
				.then(res => res.data)
				.catch(e => {
					throw e;
				});
		},

		add_floor(data) {
			return instance({
				url: "/v2/ksk/houses/constructor/floors",
				method: "post",
				data,
			})
				.then(res => res.data)
				.catch(e => {
					throw e;
				});
		},

		add_floors_many(data) {
			return instance({
				url: "/v2/ksk/houses/constructor/floors/many",
				method: "post",
				data,
			})
				.then(res => res.data)
				.catch(e => {
					throw e;
				});
		},

		edit_floor({ id, data }) {
			return instance({
				url: `/v2/ksk/houses/constructor/floors/${id}`,
				method: "post",
				data,
			})
				.then(res => res.data)
				.catch(e => {
					throw e;
				});
		},

		delete_floor(id) {
			return instance({
				url: `/v2/ksk/houses/constructor/floors/delete/${id}`,
				method: "post",
			})
				.then(res => res.data)
				.catch(e => {
					throw e;
				});
		},

		add_apartments_many(data) {
			return instance({
				url: "/v2/ksk/houses/constructor/apartments/storeMany",
				method: "post",
				data,
			})
				.then(res => res.data)
				.catch(e => {
					throw e;
				});
		},

		edit_apartment({ id, data }) {
			return instance({
				url: `/v2/ksk/houses/constructor/apartments/${id}`,
				method: "post",
				data,
			})
				.then(res => res.data)
				.catch(e => {
					throw e;
				});
		},

		delete_board_apartment(id) {
			return instance({
				url: `/v2/ksk/houses/constructor/apartments/delete/${id}`,
				method: "post",
			})
				.then(res => res.data)
				.catch(e => {
					throw e;
				});
		},

		load_apartment_owner(id) {
			return instance({
				url: `/v2/ksk/apartments/owners/${id}`,
				method: "get",
			})
				.then(res => res.data)
				.catch(e => {
					throw e;
				});
		},
	};
}
