import $api from "@/api/index";

const state = {
	employee: [],
	houses: [],
	ksk_ids: [],
	page: null
};

const getters = {
	// Данные о всех сотрудниках
	GET_EMPLOYEE: state => state.employee,
	// Имена и _id всех сотрудников
	GET_EMPLOYEE_NAMES: state => {
		return state.employee.map(e => {
			return {
				id: e.id,
				fullname: (
					e?.name +
					" " +
					e?.surname +
					" " +
					(e?.patronymic || "")
				).trim(),
			};
		});
	},
	// Данные о всех домах
	GET_HOUSES: state => state.houses,
	//Фильтр поиска кск
	GET_KSK_IDS: state => state.ksk_ids,
	//Фильтр поиска страницы
	GET_PAGE: state => state.page,
};

const mutations = {
	SET_EMPLOYEE: (state, data) => (state.employee = data),
	SET_HOUSES: (state, data) => (state.houses = data),
	SET_KSK_IDS: (state, data) => (state.ksk_ids = data),
	SET_PAGE: (state, data) => (state.page = data),
};

const actions = {
	async load_houses({ rootGetters, commit }, params = {}) {
		try {
			// const { city_id } = rootGetters["ksk/GET_KSK_INFO"];
			let res = await $api.dispatcher.load_houses(params);
			commit("SET_HOUSES", res);
		} catch (error) {
			throw error;
		}
		
	},
	load_ksk_ids(context, data) {
		context.commit("SET_KSK_IDS", data);
	},
	load_page(context, data) {
		context.commit("SET_PAGE", data);
	}
	// async load_employee({ commit }, params) {
	// 	try {
	// 		const res = await $api.ksk.load_employee(params);
	// 		commit("SET_EMPLOYEE", res);
	// 	} catch (error) {
	// 		throw error;
	// 	}
	// },
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
