<template>
	<v-btn
		v-bind="$attrs"
		class="subtitle-2 custom-capitalize text-none"
		depressed
		:color="color"
	>
		<slot> </slot>
	</v-btn>
</template>

<script>
export default {
	name: "Button",

	props: {
		color: {
			type: String,
			default: "primary",
		},
	},
};
</script>
