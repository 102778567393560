export default function(instance) {
	return {
		// ? QR
		// * Получение логов шлагбаумов
		load_logs_barrier(params) {
			params = { type: "entry", ...params };
			return instance({
				url: `/v2/ksk/qr`,
				method: "get",
				params,
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},
		// * Получение логов подъездов
		load_logs_porch(params) {
			params = { ...params, type: "entrance" };
			return instance({
				url: `/v2/ksk/qr`,
				method: "get",
				params,
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},
		// * Получение домов с/без QR для логов
		load_houses_qr_porch(params) {
			return instance({
				url: `/v2/ksk/house/qr`,
				method: "get",
				params,
			})
				.then(res => res.data.records)
				.catch(err => {
					throw err;
				});
		},
		// * Генерация QR
		generate_qr_porch(params) {
			return instance({
				url: "/v2/ksk/qr/generate",
				method: "get",
				responseType: "arraybuffer",
				params,
			})
				.then(res => {
					const dec = new TextDecoder();
					const decoded = dec.decode(res.data);
					if (decoded.includes("PNG")) {
						return window.URL.createObjectURL(new Blob([res.data]));
					}
					const data = JSON.parse(decoded);
					if (data.error) {
						throw { message: data.error };
					}
				})
				.catch(err => {
					throw err;
				});
		},

		// * Блокировка пользователя для въезда
		qr_block_user(data) {
			return instance({
				url: `/v2/ksk/qr/reset`,
				method: "post",
				data,
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},

		// * Получение въездов
		load_entries() {
			return instance({
				url: `/v2/ksk/entry`,
				method: "get",
			})
				.then(res => res.data.records)
				.catch(err => {
					throw err;
				});
		},
		// * Получение белого листа
		load_whitelist(params) {
			return instance({
				url: `/v2/ksk/entry/white-list`,
				method: "get",
				params,
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},
		// * Генерация QR въезд
		generate_qr_entry(params) {
			return instance({
				url: `/v2/ksk/qr/generate/entry`,
				method: "get",
				params,
			})
				.then(res => res.data.files)
				.catch(err => {
					throw err;
				});
		},
		// * Установка пароля от Wifi у дома
		set_wifi_password(id, params) {
			return instance({
				url: `/v2/ksk/houses/${id}/rca`,
				method: "post",
				params,
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},
	};
}
