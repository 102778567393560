import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

import ru from "vuetify/es5/locale/ru";
import en from "vuetify/es5/locale/en";

Vue.use(Vuetify);

export default new Vuetify({
	lang: {
		locales: { ru, en },
		current: "ru",
	},
	theme: {
		themes: {
			light: {
				primary: "#2EB85C",
				secondary: "#424242",
				accent: "#82B1FF",
				error: "#FF5252",
				info: "#2196F3",
				success: "#4CAF50",
				warning: "#FFC107",
				customRed: "#D65858",
				customWarning: "#E49C02"
			},
		},
	},
});
