import $api from "@/api/index";

const state = {
	search: {
		porch: null,
		fullname: null,
		room: null,
	},
	entrances: [],
};

const getters = {
	GET_ENTRANCES: state => state.entrances,
	GET_SELECTED_PORCH: state => {
		if(state.entrances) {
			state.entrances.find(e => e._id === state.search.porch)
		}
	},
	GET_SEARCH_PORCH: state => state.search["porch"],
	GET_SEARCH_FULLNAME: state => state.search["fullname"],
	GET_SEARCH_ROOM: state => state.search["room"],
	GET_SEARCH_RESULT: (state, getters) => {
		const porch = getters["GET_SELECTED_PORCH"];
		const { fullname, room } = state.search;

		if (!porch || (!fullname && !room)) return null;

		const { floors } = state.entrances.find(e => e._id === porch._id);

		let result = null;
		for (const f of floors) {
			const found = f.apartments.find(a => {
				let match = true;
				if (fullname) match = a.phone?.includes(fullname);
				if (match && room)
					match = a.apartment_number && a.apartment_number == room;
				return match;
			});

			if (found) {
				result = found;
				break;
			}
		}

		return result;
	},
};

const mutations = {
	SET_ENTRANCES: (state, data) => (state.entrances = data),
	SET_SEARCH: (state, data) => {
		if (data === null)
			return (state.search = {
				porch: null,
				fullname: null,
				room: null,
			});

		state.search[data.field] = data.value;
	},
};

const actions = {
	async load({ commit }, house_id) {
		try {
			const res = await $api.ksk.load_house_by_id(house_id);
			commit("SET_ENTRANCES", res.entrances);
		} catch (error) {
			throw error;
		}
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
