export default function(instance) {
	return {
		// ? VOTE (ГОЛОСОВАНИЯ)
		// * Получение всех голосований
		load_votes_new(params) {
			return instance({
				url: `/votes`,
				method: "get",
				params,
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},
		load_votes(params) {
			return instance({
				url: `/v2/ksk/votes`,
				method: "get",
				params,
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},

		// * Просмотр конкретного голосования
		load_vote_info_new(id) {
			return instance({
				url: `/votes/${id}`,
				method: "get",
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},
		load_vote_lists(id) {
			return instance({
				url: `/votes/${id}/lists`,
				method: "get",
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},
		load_vote_info(id) {
			return instance({
				url: `/v2/ksk/votes/${id}`,
				method: "get",
			})
				.then(res => res.data.vote)
				.catch(err => {
					throw err;
				});
		},

		// * Добавление листов оффлайн
		add_vote_offline_lists(data, id) {
			return instance({
				url: `/votes/${id}/offline/lists`,
				method: "post",
				data,
			})
				.then(response => response.data.data)
				.catch(err => {
					throw err;
				});
		},
		add_vote_v3(data) {
			return instance({
				url: `/votes`,
				method: "post",
				data,
			})
				.then(response => response.data.data)
				.catch(err => {
					throw err;
				});
		},
		edit_vote_v3(data, id) {
			return instance({
				url: `/votes/${id}`,
				method: "post",
				data,
			})
				.then(response => response.data.data)
				.catch(err => {
					throw err;
				});
		},
		edit_vote_question_v3(data, id) {
			return instance({
				url: `/votes/questions/${id}`,
				method: "post",
				data,
			})
				.then(response => response.data.data)
				.catch(err => {
					throw err;
				});
		},
		add_room(data, id) {
			return instance({
				url: `/votes/${id}/gdbrn/rooms`,
				method: "post",
				data,
			})
				.then(response => response.data.data)
				.catch(err => {
					throw err;
				});
		},
		add_rooms(data, id) {
			return instance({
				url: `/votes/${id}/gdbrn/rooms/many`,
				method: "post",
				data,
			})
				.then(response => response.data.data)
				.catch(err => {
					throw err;
				});
		},
		set_offline_count(data, id, optId) {
			return instance({
				url: `/votes/questions/${id}/options/${optId}/set-vote-offine-count`,
				method: "post",
				data,
			})
				.then(response => response.data.data)
				.catch(err => {
					throw err;
				});
		},
		edit_vote_room(data, id) {
			return instance({
				url: `/votes/gdbrn/rooms/${id}`,
				method: "post",
				data,
			})
				.then(response => response.data.data)
				.catch(err => {
					throw err;
				});
		},
		delete_offline_list(id) {
			return instance({
				url: `/votes/${id}/offline/lists`,
				method: "delete",
			})
				.then(response => response.data.data)
				.catch(err => {
					throw err;
				});
		},
		delete_vote_question_v3(id) {
			return instance({
				url: `/votes/questions/${id}`,
				method: "delete",
			})
				.then(response => response.data.data)
				.catch(err => {
					throw err;
				});
		},
		delete_vote_room(id) {
			return instance({
				url: `/votes/gdbrn/rooms/${id}`,
				method: "delete",
			})
				.then(response => response.data.data)
				.catch(err => {
					throw err;
				});
		},
		vote_generate_protocol(id, params) {
			return instance({
				url: `/votes/${id}/generate-protocol`,
				method: "get",
				params
			})
				.then(response => response.data.data)
				.catch(err => {
					throw err;
				});
		},
		set_offline_vote(id, roomId, params) {
			return instance({
				url: `/votes/${id}/gdbrn/rooms/${roomId}/set-offine-vote`,
				method: "post",
				params
			})
				.then(response => response.data.data)
				.catch(err => {
					throw err;
				});
		},
		load_gdbrn_info(id, params) {
			return instance({
				url: `/votes/${id}/gdbrn/types/rooms`,
				method: "get",
				params
			})
				.then(response => response.data.data)
				.catch(err => {
					throw err;
				});
		},
		load_votes_statuses() {
			return instance({
				url: `/votes/statuses`,
				method: "get",
			})
				.then(response => response.data.data)
				.catch(err => {
					throw err;
				});
		},
		add_vote(data) {
			return instance({
				url: `/v2/ksk/votes`,
				method: "post",
				data,
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},

		// * Добавление вопроса
		add_vote_question(vote_id, data) {
			return instance({
				url: `/v2/ksk/votes/questions/${vote_id}`,
				method: "post",
				data,
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},

		// * Редактирование вопроса
		edit_vote_question(id, data) {
			return instance({
				url: `/v2/ksk/votes/questions/${id}/update`,
				method: "post",
				data,
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},

		// * Удаление вопроса
		delete_vote_question(id) {
			return instance({
				url: `/v2/ksk/votes/questions/${id}/delete`,
				method: "post",
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},

		// * Удаление файла в вопросе голосования
		delete_file_question(id, fileId) {
			return instance({
				url: `/v2/ksk/votes/questions/${id}/delete/file/${fileId}`,
				method: "post",
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},

		// * Получение квартир, участвующих в голосвовании
		load_vote_apartments(vote_id, params) {
			return instance({
				url: `/v2/ksk/votes/apartments/${vote_id}`,
				method: "get",
				params,
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},

		//  * Добавление квартиры
		vote_add_single_apartment(data) {
			return instance({
				url: `/v2/ksk/votes/apartments`,
				method: "post",
				data,
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},
		//  * Добавление квартиры
		vote_store_apartment(data) {
			return instance({
				url: `/v2/ksk/votes/apartments/store/apartment`,
				method: "post",
				data,
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},

		// * Добавление множества квартир
		vote_add_multiple_apartments(data) {
			return instance({
				url: `/v2/ksk/votes/apartments/many/store`,
				method: "post",
				data,
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},

		// * Удаление квартиры
		delete_apartment(id) {
			return instance({
				url: `/v2/ksk/votes/apartments/${id}/delete`,
				method: "post",
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},

		// * Изменения ИИН квартиры
		set_apartment_iin(apartment_id, data) {
			return instance({
				url: `/v2/ksk/votes/apartments/${apartment_id}`,
				method: "post",
				data,
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},

		// * Публикация голосования
		publish_vote(id, data) {
			return instance({
				url: `/v2/ksk/votes/${id}`,
				method: "post",
				data,
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},

		// * Удаление голосования
		delete_vote_v3(id) {
			return instance({
				url: `/votes/${id}`,
				method: "delete",
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},
		delete_vote(id) {
			return instance({
				url: `/v2/ksk/votes/destroy/${id}`,
				method: "post",
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},

		// * Получение протокола
		get_protocol(id) {
			return instance({
				url: `/v2/ksk/votes/publish/report/${id}`,
				method: "post",
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},
		// * Получение архива
		get_zip(id, params) {
			return instance({
				url: `/v2/ksk/votes/zip/${id}`,
				method: "post",
				responseType: 'blob',
				data: params
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},
	};
}
