import faq from "./administrator/faq";
import posts from "./administrator/posts";
import houses from "./administrator/houses";

export default function(instance) {
	return {
		...faq(instance),
		...posts(instance),
		...houses(instance),

		// ? QR
		// * Получение логов шлагбаумов
		load_logs_barrier(params) {
			params = { ...params, type: "entry" };
			return instance({
				url: `/v2/administrator/qr`,
				method: "get",
				params,
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},
		// * Получение логов подъездов
		load_logs_porch(params) {
			params = { ...params, type: "entrance" };
			return instance({
				url: `/v2/administrator/qr`,
				method: "get",
				params,
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},
		// * Получение домов с/без QR для логов
		load_houses_qr_porch(params) {
			return instance({
				url: `/v2/administrator/house/qr`,
				method: "get",
				params,
			})
				.then(res => res.data.records)
				.catch(err => {
					throw err;
				});
		},
		// * Генерация QR
		generate_qr_porch(params) {
			return instance({
				url: "/v2/administrator/qr/generate",
				method: "get",
				responseType: "arraybuffer",
				params,
			})
				.then(res => {
					const dec = new TextDecoder();
					const decoded = dec.decode(res.data);
					if (decoded.includes("PNG")) {
						return window.URL.createObjectURL(new Blob([res.data]));
					}
					const data = JSON.parse(decoded);
					if (data.error) {
						throw { message: data.error };
					}
				})
				.catch(err => {
					throw err;
				});
		},
		// * Получение въездов
		load_entries() {
			return instance({
				url: `/v2/administrator/entry`,
				method: "get",
			})
				.then(res => res.data.records)
				.catch(err => {
					throw err;
				});
		},
		// * Отправка параметра аналитики
		save_ksk_analytic(data, id) {
			return instance({
				url: `/company/${id}`,
				method: "post",
				data
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},
		// * Сброс пароля
		reset_password(params) {
			return instance({
				url: `/users/generate-sms-code`,
				method: "post",
				params
			})
				.then(res => res)
				.catch(err => {
					throw err;
				});
		},
		// * Получение всех УК
		load_ksk(params) {
			return instance({
				url: `/company`,
				method: "get",
				params
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},
		// * Получение токена УК 
		getTokenKsk(id) {
			return instance({
				url: `/company/${id}/auth`,
				method: "post"
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},
		// * Подтверждение УК
		confirm_ksk(id, status) {
			return instance({
				url: `/company/${id}`,
				method: "post",
				data: {confirmed: status, _method: 'patch'}
			})
				.then(res => res.data.records)
				.catch(err => {
					throw err;
				});
		},
		// * Подтверждение для Диспетчеров
		confirm_ksk_dispatcher(id, status) {
			return instance({
				url: `/company/${id}`,
				method: "post",
				data: {confirmed_dispatcher: status, _method: 'patch'}
			})
				.then(res => res.data.records)
				.catch(err => {
					throw err;
				});
		},
		// ? WHILTELIST
		// * Удаление из белого листа
		delete_from_whitelist(id) {
			return instance({
				url: `/v2/administrator/entry/white-list/delete/${id}`,
				method: "post",
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},
		// * Редактирование пользователя в белом лисье
		edit_whitelist_entry({ id, data }) {
			return instance({
				url: `/v2/ksk/entry/white-list/update/${id}`,
				method: "post",
				data,
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},
		// * Получение белого листа
		load_whitelist(params) {
			return instance({
				url: `/v2/administrator/entry/white-list`,
				method: "get",
				params,
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},
		// * Генерация QR въезд
		generate_qr_entry(params) {
			return instance({
				url: `/v2/administrator/qr/generate/entry`,
				method: "get",
				params,
			})
				.then(res => res.data.files)
				.catch(err => {
					throw err;
				});
		},
		// * Установка пароля от Wifi у дома
		set_wifi_password(id, params) {
			return instance({
				url: `/v2/administrator/house/${id}`,
				method: "post",
				params,
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},
		// ? TENANTS (ЖИТЕЛИ)
		// * Блокировка пользователя
		block_tenant({ id, data }) {
			return instance({
				url: `/users/${id}`,
				method: "get",
				data,
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},
		// * Разблокиврока пользователя
		unblock_tenant(id) {
			return instance({
				url: `/users/${id}`,
				method: "get",
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},
	};
}
