export default function(instance) {
	return {
		// ? QR и ВЪЕЗДЫ (ENTRIES)
		// * Добавление въезда
		add_entry(data) {
			return instance({
				url: `/v2/ksk/entry`,
				method: "post",
				data,
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},
		// * Удаление въезда
		delete_entry(id) {
			return instance({
				url: `/v2/ksk/entry/delete/${id}`,
				method: "post",
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},
		edit_entry(id, data) {
			return instance({
				url: `/v2/ksk/entry/update/${id}`,
				method: "post",
				data,
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},
		// * Добавление в белый список
		add_to_whitelist(data) {
			return instance({
				url: `/v2/ksk/entry/white-list`,
				method: "post",
				data,
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},
		// * Удаление из белого листа
		delete_from_whitelist(id) {
			return instance({
				url: `/v2/ksk/entry/white-list/delete/${id}`,
				method: "post",
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},

		// * Редактирование пользователя в белом лисье
		edit_whitelist_entry({ id, data }) {
			return instance({
				url: `/v2/ksk/entry/white-list/update/${id}`,
				method: "post",
				data,
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},
	};
}
