<template>
	<span class="label subtitle-2 font-weight-light">
		<slot>
			{{ text }}
		</slot>
	</span>
</template>

<script>
export default {
	name: "Label",

	props: {
		text: {
			type: String,
			default: "",
		},
	},
};
</script>

<style></style>
