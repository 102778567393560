export default function(instance) {
  return {
    load_houses(params) {
      return instance({
        url: "/houses",
        method: "get",
        params: {
          ...params,
          page: params?.page || 1,
        },
      })
        .then(res => res.data)
        .catch(err => {
          throw err;
        });
    },
  }
}