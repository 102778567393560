export default function(instance) {
	return {
		// ? EMPLOYEE (СОТРУДНИКИ)
		// * Получение всех сотрудника
		load_employee(params) {
			return instance({
				url: `/users`,
				headers: { "Content-Type": "application/json" },
				method: "get",
				params,
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},
		load_employee_by_id(id, ksk_id) {
			return instance({
				url: `/users/${id}/?selected_ksk_id=${ksk_id}`,
				headers: { "Content-Type": "application/json" },
				method: "get",
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},
		// * Получение всех или определенного сотрудника чистый лист (без ролей, без ид домов и ид категорий)
		load_employee_list(params) {
			const id = params ? params.id : false;
			return instance({
				url: `/v2/ksk/employee/get-list/${id || ""}`,
				method: "get",
				params,
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},

		// * Экспорт сотрудников в excel
		export_employee_list(params) {
			return instance({
				url: `/v2/ksk/employee/export/excel`,
				responseType: "blob",
				contentType:
					"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
				params,
			})
				.then(res => window.URL.createObjectURL(new Blob([res.data])))
				.catch(error => error);
		},

		// * Добавление сотрудника
		add_employee(data) {
			return instance({
				url: "/users",
				method: "post",
				data,
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},

		// * Сохранение сотрудника
		save_employee(data, id, ksk_id) {
			const url = `/users/${id}${ksk_id ? `?selected_ksk_id=${ksk_id}` : ''}`;
			return instance({
				url: url,
				method: "post",
				data,
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},
		// * Сохранение user
		save_user(data, id, kskId) {
			return instance({
				url: `/users/${id}?selected_ksk_id=${kskId}`,
				method: "post",
				data,
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},
	};
}
