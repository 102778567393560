<template>
	<span v-bind="$attrs" class="text-box text-subtitle-1 font-weight-regular">
		<v-skeleton-loader
			v-if="loading"
			type="text"
			:width="width"
		></v-skeleton-loader>
		<span v-else>
			<slot> </slot>
		</span>
	</span>
</template>

<script>
export default {
	name: "TextBox",

	props: {
		loading: {
			type: Boolean,
			default: false,
		},
		width: {
			type: [String, Number],
			default: "200px",
		},
	},
};
</script>

<style lang="scss" scoped>
.text-box {
	line-height: 0 !important;
}
</style>
