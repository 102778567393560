<template>
	<v-app>
		<router-view></router-view>
		<div id="tokenContainer" style="display: none;"></div>
		<div id="permContainer" style="display: none;"></div>
	</v-app>
</template>

<script>
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';
const firebaseConfig = { 
  apiKey: "AIzaSyApKShjKB2DBa0MuU4xCUEu7sIEt1IS8a8", 
  authDomain: "eksk-api.firebaseapp.com", 
  databaseURL: "https://eksk-api.firebaseio.com", 
  projectId: "eksk-api", 
  storageBucket: "eksk-api.appspot.com", 
  messagingSenderId: "627477012100", 
  appId: "1:627477012100:web:bec18216df65eaf9ee6cd1", 
  measurementId: "G-L1VS18L2NK" 
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export default {
	name: "App",
	data: () => ({}),
	async created() {
    try {
			console.log('init');
      const token = await getToken(messaging);
			console.log('Разрешение на уведомления получено.');
			console.log('Токен устройства:', token);
			const tokenContainer = document.getElementById('tokenContainer');
  		tokenContainer.textContent = token;
			this.$store.dispatch("notifications/saveToken", token);
    } catch (error) {
      console.error('Ошибка при запросе разрешения на уведомления:', error);
			const tokenContainer = document.getElementById('tokenContainer');
  		tokenContainer.textContent = error;
			if (error.code === 'messaging/permission-blocked') {
        try {
          await Notification.requestPermission();
          console.log('Разрешение на уведомления получено после запроса.');
          console.log('Состояние разрешения на уведомления:', Notification.permission);
					const permsContainer = document.getElementById('permContainer');
  				permsContainer.textContent = 'Состояние разрешения на уведомления:' + Notification.permission;
          const token = await getToken(messaging);
					const tokenContainer = document.getElementById('tokenContainer');
					tokenContainer.textContent = token;
					this.$store.dispatch("notifications/saveToken", token);
        } catch (permissionError) {
          console.error('Ошибка при запросе разрешения на уведомления после запроса:', permissionError);
					const tokenContainer = document.getElementById('permContainer');
  				tokenContainer.textContent = permissionError;
        }
      }
    }
  },
	async mounted() {
		this.$store.dispatch("catalog/load");
		const logoutPerformed = localStorage.getItem('logoutPerformed');
		if (!logoutPerformed) {
      this.$store.dispatch("auth/logout");
			this.$router.push("/login");
      localStorage.setItem('logoutPerformed', 'true');
    }
	},
};
</script>

<style lang="scss">
@import "./assets/scss/style.scss";

.v-text-field.v-text-field--solo.v-input--dense {
	&:focus-within {
		border-color: #77dc99 !important;
		box-shadow: 0px 0px 4px #81e9a3;
	}
	&.error--text {
		position: relative;
		border-color: red !important;
		&:focus-within {
			box-shadow: 0px 0px 4px rgb(255, 97, 97);
		}
		.v-text-field__details {
			display: block !important;
			position: absolute;
			bottom: -25px;
			left: -10px;
		}
		.v-input__slot {
			margin-bottom: 0px !important;
		}
	}
}

.v-text-field.v-text-field--solo.v-input--dense > .v-input__control {
	min-height: 33px !important;
	font-size: 14px !important;
}

.v-data-table:not(.no-pagination) .v-data-table__wrapper::-webkit-scrollbar {
	-webkit-appearance: none !important;
}
.v-data-table:not(.no-pagination)
	.v-data-table__wrapper::-webkit-scrollbar:horizontal {
	height: 6px !important;
	background-color: rgba(0, 0, 0, 0.1);
}
.v-data-table:not(.no-pagination)
	.v-data-table__wrapper::-webkit-scrollbar:vertical {
	-webkit-appearance: auto !important;
	width: 6px !important;
}
.v-data-table:not(.no-pagination)
	.v-data-table__wrapper::-webkit-scrollbar-thumb {
	border-radius: 8px !important;
	border: 1px solid #2eb85c !important;
	background-color: #2eb85c !important;
}

// Custom v-text-field.solo
.v-data-table
	.v-text-field.v-text-field--solo.v-input--dense
	> .v-input__control {
	min-height: 27px !important;
	font-size: 14px !important;
}
.v-text-field.v-text-field--solo.v-input--dense .v-text-field__details {
	display: none !important;
}

// V-data-table styles
.v-data-table {
	overflow-x: auto !important;
	tbody td,
	tbody th {
		height: 58px !important;
	}
	tbody tr {
		cursor: pointer;
		&:nth-child(1) td {
			height: 42px !important;
		}
		&:nth-of-type(even):not(:nth-child(1)) {
			background-color: rgba(0, 0, 0, 0.05) !important;
		}
	}
}
</style>
