import $api from "@/api/index";

const state = {
	gates: [],
	entries: [],
	housesWithQrPorch: [],
	housesWithoutQrPorch: [],
};

const getters = {
	GET_USER_ROLE: (state, getters, rootState, rootGetters) =>
		rootGetters["auth/GET_USER_ROLE_VUEX"],
	GET_ENTRIES: state => state.entries,
	GET_GATES: state => state.gates,
	// Дома с QR (Подъезды)
	GET_HOUSES_WITH_QR_PORCH: state => state.housesWithQrPorch,
	// Дома без QR (Подъезды)
	GET_HOUSES_WITHOUT_QR_PORCH: state => state.housesWithoutQrPorch,
};

const mutations = {
	SET_GATES: (state, data) => (state.gates = data),
	SET_ENTRIES: (state, data) => (state.entries = data),
	SET_HOUSES_WITH_QR_PORCH: (state, data) => (state.housesWithQrPorch = data),
	SET_HOUSES_WITHOUT_QR_PORCH: (state, data) =>
		(state.housesWithoutQrPorch = data),
};

const actions = {
	async load_houses_qr_porch({ commit, getters }) {
		try {
			// С QR
			// TODO $api[getters.GET_USER_ROLE] когда Никита добавит
			let res = await $api.administrator.load_houses_qr_porch({
				qr_created: true,
			});
			commit("SET_HOUSES_WITH_QR_PORCH", res);
			// Без QR
			res = await $api.administrator.load_houses_qr_porch({
				qr_created: false,
			});
			commit("SET_HOUSES_WITHOUT_QR_PORCH", res);
		} catch (error) {
			throw error;
		}
	},

	async load_entries({ commit, getters }) {
		try {
			const res = await $api[getters.GET_USER_ROLE].load_entries();
			commit("SET_ENTRIES", res);
		} catch (error) {
			throw error;
		}
	},

	async load_gates({ commit }) {
		try {
			const res = await $api.ksk.load_gates();
			commit("SET_GATES", res.records);
		} catch (error) {
			throw error;
		}
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
