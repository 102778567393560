export default function(instance) {
	return {
		// ? NOTIFICATIONS (УВЕДОМЛЕНИЯ)
		create_notification(data) {
			return instance({
				url: "/notifications",
				method: "post",
				data,
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},

		get_notifications(params) {
			return instance({
				url: "/notifications",
				method: "get",
				params,
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},
		get_notification(id) {
			return instance({
				url: `/notifications/${id}`,
				method: "get",
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},

		export_notifications_list(params) {
			return instance({
				url: "/v2/ksk/notification/export",
				responseType: "blob",
				contentType:
					"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
				params,
			})
				.then(res => window.URL.createObjectURL(new Blob([res.data])))
				.catch(error => error);
		},

		edit_notification({ id, data }) {
			return instance({
				url: `/notifications/${id}`,
				method: "post",
				data,
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},

		delete_notification(id) {
			return instance({
				url: `/notifications/${id}`,
				method: "delete",
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},

		delete_notification_file({ fId, nId }) {
			return instance({
				url: `/v2/ksk/notification/${nId}/file/${fId}`,
				method: "delete",
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},
	};
}
