export default function(instance) {
	return {
		// ? REQUESTS (ЗАЯВКИ)
		// * Получение всех заявок
		load_request(params) {
			return instance({
				url: "/requests",
				method: "get",
				params,
			})
				.then(res => res.data)
				.catch(error => error);
		},

		// * Создание новой заявки
		add_request(data) {
			return instance({
				url: "/bpmn/requests",
				method: "post",
				data,
			})
				.then(res => res.data)
				.catch(error => error);
		},

		// * Восстановление заявки БМПН
		reopen_request(data) {
			return instance({
				url: `/bpmn/requests/reopen`,
				method: "post",
				data,
			})
				.then(res => res.data)
				.catch(error => error);
		},

		// * Принятие заявки БМПН
		assign_request(data, id) {
			return instance({
				url: `/bpmn/requests/${id}/request-assigned-event`,
				method: "post",
				data,
			})
				.then(res => res.data)
				.catch(error => error);
		},

		// * Взятие в работу заявки БМПН
		accept_request(data, id) {
			return instance({
				url: `/bpmn/requests/${id}/request-in-work-event`,
				method: "post",
				data,
			})
				.then(res => res.data)
				.catch(error => error);
		},
		// * Закрытие заявки БМПН
		close_request(data, id) {
			return instance({
				url: `/bpmn/requests/${id}/request-is-done-event`,
				method: "post",
				data,
			})
				.then(res => res.data)
				.catch(error => error);
		},
		// * Закрытие не исполненной заявки БМПН 
		done_request_event(data, id) {
			return instance({
				url: `/bpmn/requests/${id}/request-new-to-done-event`,
				method: "post",
				data,
			})
				.then(res => res.data)
				.catch(error => error);
		},

		// * Отклонение заявки БМПН
		rejected_request_bpmn(data, id) {
			return instance({
				url: `/bpmn/requests/${id}/request-rejected-event`,
				method: "post",
				data,
			})
				.then(res => res.data)
				.catch(error => error);
		},

		// * Детали определенной заявки
		load_request_detail(id) {
			return instance({
				url: `/requests/${id}`,
				method: "get",
			})
				.then(res => res.data)
				.catch(error => error);
		},

		// * Комментарии определенной заявки
		load_request_comments(params) {
			return instance({
				url: "/comments/",
				method: "get",
				params,
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},

		// * Оценивание заявки
		rate_request({ id, data }) {
			return instance({
				url: `/bpmn/requests/${id}/request-confirmed-event`,
				method: "post",
				data,
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},

		// * Отправка коммента по определенной заявке
		send_request_comment(data) {
			return instance({
				url: "/comments",
				method: "post",
				data,
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},
		delete_comment(id) {
			return instance({
				url: `/comments/${id}`,
				method: "delete",
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},
		edit_comment(data, id) {
			return instance({
				url: `/comments/${id}`,
				method: "post",
				data
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},

		// * Изменение рейтинга заявки
		change_request_rating({ id, user_rating }) {
			return instance({
				url: `/v2/ksk/request/${id}/rate`,
				method: "post",
				data: { user_rating },
			})
				.then(res => res.data)
				.catch(error => error);
		},

		// * Сохранение заявки
		save_request(data, id) {
			return instance({
				url: `/bpmn/requests/${id}/request-updated-event`,
				method: "post",
				data,
			})
				.then(res => res.data)
				.catch(error => error);
		},

		// * Закрытие заявки (используется маршрут сотрудника)
		finish_request(data, id) {
			return instance({
				url: `/v2/ksk/employee/request/closing-work/${id}`,
				method: "post",
				data,
			})
				.then(res => res.data)
				.catch(error => error);
		},

		// * Экспорт заявок в Excel
		export_requests_list(params) {
			return instance({
				url: `/requests/`,
				responseType: "blob",
				contentType:
					"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
				params,
			})
				.then(res => window.URL.createObjectURL(new Blob([res.data])))
				.catch(error => error);
		},
	};
}
