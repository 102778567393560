export default function(instance) {
	return {
		load_gates() {
			return instance({
				url: `/v2/ksk/gates`,
				method: "get",
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},

		add_gate(data) {
			return instance({
				url: `/v2/ksk/gates`,
				method: "post",
				data,
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},
		delete_gate(id) {
			return instance({
				url: `/v2/ksk/gates/delete/${id}`,
				method: "post",
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},
		edit_gate(id, data) {
			return instance({
				url: `/v2/ksk/gates/update/${id}`,
				method: "post",
				data,
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},
		// * Генерация QR въезд
		generate_qr_gate(params) {
			return instance({
				url: `/v2/ksk/qr/generate/gate`,
				method: "get",
				params,
			})
				.then(res => res.data.files)
				.catch(err => {
					throw err;
				});
		},
	};
}
