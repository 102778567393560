export default function(instance) {
	return {
		load_houses(params) {
			return instance({
				url: "/houses?short=true&visible=true",
				method: "get",
				params: { ...params, all: true },
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},
	};
}
