<template>
	<div class="text-field d-flex" :class="divClass">
		<label
			v-if="label"
			class="text-subtitle-2 font-weight-light"
			:class="labelClass"
		>
			{{ label }}
		</label>

		<v-text-field
			v-bind="$attrs"
			class="border-all"
			:class="{ 'active': active }"
			dense
			flat
			solo
			hide-details
			:style="inputStyle"
			:value="value"
			@input="handleInput"
			@focus="handleFocus"
			@blur="handleBlur"
		>
		</v-text-field>
	</div>
</template>

<script>
export default {
	name: "TextField",
	inheritAttrs: false,

	props: {
		active: {
			type: Boolean,
			default: false,
		},
		value: {
			required: true,
		},
		label: {
			type: [String, Number],
			default: "",
		},
		row: {
			type: Boolean,
			default: false,
		},
		inputWidth: {
			type: [String, Number],
			default: "",
		},
		minWidth: {
			type: [String, Number],
			default: "",
		},
	},

	computed: {
		divClass() {
			return this.row ? "align-center" : "flex-column";
		},
		labelClass() {
			if (!this.label) return "";
			return this.row ? "mr-4" : "mb-2";
		},
		inputStyle() {
			let style = "";
			if (this.inputWidth) style += `width: ${this.inputWidth}px;`;
			if (this.minWidth) style += `min-width: ${this.minWidth}px;`;
			return style;
		},
	},

	methods: {
		handleInput(e) {
			this.$emit("input", e);
			this.$emit("update:value", e);
		},
		handleFocus() {
			this.$emit("focus");
		},
		handleBlur() {
			this.$emit("blur");
		},
	},
};
</script>

<style lang="scss" scoped>
.v-text-field.active {
	border-color: #77dc99 !important;
}
/* Chrome, Safari, Edge, Opera */
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
::v-deep input[type="number"] {
	-moz-appearance: textfield;
}
</style>
