import $api from "@/api/index";
import cookies from "@/helpers/cookies";
import store from '@/store'
import axios from "axios";
import { setTimeout } from "core-js";

const state = {
	individualData: null,
	authToken: null,
	adminUserId: null,
	adminToken: null,
	phoneCode: null,
	userPhoneCode: '+7',
	ksk_id: null,
	accessActionInProgress: false,
	access_token_ttl: 300,
	lastTokenRefresh: null,
	lastActionTime: Date.now(),
};

const getters = {
	GET_INDIVIDUAL_DATA: state => state.individualData,
	GET_USER_ID: state => state.individualData?.id || null,
	// ? Получить роль для обычных целей
	GET_USER_ROLE: state => {
		console.log('state.individualData', state)
		if (!state.individualData?.role) return null;
		const catalog = store.getters["catalog/GET_CATALOG"];
		const role = catalog.roles.find(e => e.id === state.individualData.role.id);
		console.log('GET_USER_ROLE', role)
		// if(role?.name_system == 'ksk_dispatcher') return 'dispatcher'
		return role?.name_system;
	},
	// ? Получить роль для обращения к api запросам (меняет ksk_manager на ksk)
	GET_USER_ROLE_VUEX: (state, getters) => {
		const role = getters.GET_USER_ROLE;
		if (role === "ksk_manager") return "ksk";
		if (~role?.indexOf("dispatcher")) return "dispatcher";
		return role;
	},
	GET_KSK_ID: state => {
		return state.individualData?.ksk !== null ? state.individualData.ksk.length < 2 ? state.individualData.ksk[0].id : state.ksk_id : null
	},
	GET_COMPANY_TYPE: state => {
		if(state.individualData) {
			if(state.individualData?.ksk !== null) {
				if(state.individualData.ksk.length < 2) {
					return state.individualData.ksk[0].company_type.sys_name
				} else if(state.ksk_id) {
					return state.individualData.ksk.find(e => e.id === state.ksk_id).company_type.sys_name
				}
			}
		}
		return null
	},
	GET_HOUSES_IDS: state => state.individualData.ksk_houses,
	GET_COMPANY_IDS: state => state.individualData.ksk,
	GET_USER_CATEGORIES: state => state.individualData.ksk_category.map(item => item.category),
	GET_TOKEN: state => state.authToken,
	GET_ADMIN_TOKEN: state => state.adminToken,
	GET_ADMIN_USER_ID: state => state.adminUserId,
	GET_PHONE_CODE: state => state.phoneCode,
	GET_USER_PHONE_CODE: state => state.userPhoneCode,
};

const mutations = {
	SET_INDIVIDUAL_DATA: (state, data) => (state.individualData = data),
	SET_TOKEN: (state, token) => (state.authToken = token),
	SET_ADMIN_TOKEN: (state, token) => (state.adminToken = token),
	SET_PHONE_CODE: (state, data) => (state.phoneCode = data),
	SET_USER_PHONE_CODE: (state, data) => (state.userPhoneCode = data),
	SET_KSK_ID: (state, data) => (state.ksk_id = data),
	SET_ADMIN_USER_ID: (state, data) => (state.adminUserId = data),
	SET_LAST_TOKEN_REFRESH: (state, data) => (state.lastTokenRefresh = data),
	setAccessActionInProgress(state, value) {
    state.accessActionInProgress = value;
  },
	setAccessTokenTTL(state, value) {
    state.access_token_ttl = value;
  },
	setLastActionTime(state, time) {
    state.lastActionTime = time;
  },
};

const actions = {
	async changeAuthToken({ dispatch, commit }, token) {
		try {
		 	console.log('token admin', token)
			
			commit("SET_INDIVIDUAL_DATA", null);
			commit("SET_KSK_ID", null);
			commit("SET_TOKEN", null);

			commit("SET_TOKEN", token);
			commit("SET_ADMIN_TOKEN", null);
			cookies.set_token(token);
			await dispatch("load_data", 'admin');
			return true
		} catch (error) {
			throw error;
		}
	},
	saveAdminToken({commit}, token) {
		console.log('token', token)
		commit("SET_ADMIN_TOKEN", token);
	},
	setKskId({commit}, ksk_id) {
		commit("SET_KSK_ID", ksk_id);
	},
	savePhoneCode({commit}, phoneCode) {
		console.log('phone code', phoneCode)
		commit("SET_PHONE_CODE", phoneCode);
	},
	userSavePhoneCode({commit}, phoneCode) {
		console.log('user phone code', phoneCode)
		commit("SET_USER_PHONE_CODE", phoneCode);
	},
	async loginKsk({ rootGetters, dispatch, commit }, res) {
		try {
			const user_id = rootGetters["auth/GET_USER_ID"];
			commit("SET_ADMIN_USER_ID", user_id);

			commit("SET_INDIVIDUAL_DATA", null);
			commit("SET_KSK_ID", null);
			commit("SET_TOKEN", null);

			console.log('RES LOGIN', res)
			commit("SET_INDIVIDUAL_DATA", res.user);
			cookies.set_token(res.token);
			commit("SET_TOKEN", res.token);
			await dispatch("load_data");
			return res.success;
		} catch (error) {
			throw error;
		}
	},
	async login({ dispatch, commit }, data) {
		try {
			dispatch("logout");
			commit("SET_ADMIN_TOKEN", null);
			console.log('DATA LOGIN', data)
			const res = await $api.user.login(data);
			console.log('res!', res)
			commit("setAccessTokenTTL", res.data.access_token_ttl);
			commit('setAccessActionInProgress', false);
			function getCountryCode(phoneNumber) {
				const countryCodes = ['+7', '+998', '+971'];
				for (let i = 0; i < countryCodes.length; i++) {
					if (phoneNumber.startsWith(countryCodes[i])) {
						return countryCodes[i];
					}
				}
				return null;
			}
			const { phone } = data;
			const countryCode = getCountryCode(phone);
			console.log('countryCode', countryCode);
			cookies.set_token(res.data.token);
			cookies.set_refresh_token(res.data.refresh_token);
			commit("SET_LAST_TOKEN_REFRESH", Date.now());
			commit("SET_USER_PHONE_CODE", countryCode);
			commit("SET_TOKEN", res.data.token);
			commit("SET_INDIVIDUAL_DATA", res.data.user);
			return res.data;
		} catch (error) {
			throw error;
		}
	},

	async load_data({ rootGetters, commit }, type) {
		try {
			let user_id
			if(type === 'admin') {
				user_id = rootGetters["auth/GET_ADMIN_USER_ID"];
			} else {
				user_id = rootGetters["auth/GET_USER_ID"];
			}
			console.log('type', type)
			let ksk_id
			let params = {}
			if(type !== 'admin') {
				ksk_id = rootGetters["auth/GET_KSK_ID"];
				params= {
					selected_ksk_id: ksk_id
				}
			}
			const userData = await $api.user.load_data(params, user_id);
			console.log('userData', userData)
			commit("SET_INDIVIDUAL_DATA", userData);
		} catch (error) {
			throw error;
		}
	},

	async change_password({ commit }, { data, userId }) {
		try {
			const res = await $api.user.change_password(data, userId);
			return res;
		} catch (error) {
			throw error;
		}
	},

	async update_user_info({ dispatch }, { data, userId }) {
		try {
			console.log('userId2', userId)
			const res = await $api.user.update_user_info(data, userId);
			dispatch("load_data");
			return res;
		} catch (error) {
			throw error;
		}
	},

	async refresh({ commit }) {
		
	},
	async access({ rootGetters, commit, state }, status) {
		const token = rootGetters["auth/GET_TOKEN"];
		if (!state.accessActionInProgress && token) {
			commit('setAccessActionInProgress', true);
			try {
        const res = await $api.user.update_access();
				commit("SET_TOKEN", res.data.token);
				cookies.remove_token();
				cookies.remove_refresh_token();
				await cookies.set_token(res.data.token);
				await cookies.set_refresh_token(res.data.refresh_token);
				commit("SET_LAST_TOKEN_REFRESH", Date.now());
				commit('setAccessActionInProgress', false);
				if(status === 'reload') {
					window.location.reload()
				}
      } catch (error) {
        console.error('Error:', error);
				// if(error.message.response.status === 404 || error.message.response.status === 401) {
				window.location.assign(`/logout?token=expired`)
				store.dispatch("auth/logout");
				commit('setAccessActionInProgress', false);
				// }
      }
      
		}
	},
	async logout({ rootGetters, commit }) {
		try {
			const token = rootGetters["auth/GET_TOKEN"];
			if(token) {
					await $api.user.logout();
			}
			cookies.remove_token();
			cookies.remove_refresh_token();
			commit("SET_TOKEN", null);
			commit('setAccessActionInProgress', true);
			commit("SET_LAST_TOKEN_REFRESH", null);
			commit("SET_INDIVIDUAL_DATA", null);
			commit("SET_KSK_ID", null);
		} catch (error) {
			console.error("Error during logout:", error);
			cookies.remove_token();
			cookies.remove_refresh_token();
			commit("SET_TOKEN", null);
			commit('setAccessActionInProgress', true);
			commit("SET_LAST_TOKEN_REFRESH", null);
			commit("SET_INDIVIDUAL_DATA", null);
			commit("SET_KSK_ID", null);
		}
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
