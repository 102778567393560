// Axios interceptor
import instance from "./instance";

import administratorModule from "./administrator";
import dispatcherModule from "./dispatcher";
import dropdownsModule from "./dropdowns";
import kskModule from "./ksk";
import houseModule from "./house";
import userModule from "./user";

export default {
	administrator: administratorModule(instance),
	dispatcher: dispatcherModule(instance),
	dropdowns: dropdownsModule(instance),
	ksk: kskModule(instance),
	house: houseModule(instance),
	user: userModule(instance),
};
