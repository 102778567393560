export default function(instance) {
	return {
		load_pricelist(params) {
			return instance({
				url: "/v2/ksk/price-list/index",
				method: "get",
				params,
			})
				.then(res => res.data)
				.catch(e => {
					throw e;
				});
		},

		add_price(data) {
			return instance({
				url: "/v2/ksk/price-list",
				method: "post",
				data,
			})
				.then(res => res.data)
				.catch(e => {
					throw e;
				});
		},

		delete_price(id) {
			return instance({
				url: `/v2/ksk/price-list/${id}/delete`,
				method: "post",
			})
				.then(res => res.data)
				.catch(e => {
					throw e;
				});
		},

		edit_price({ id, data }) {
			return instance({
				url: `/v2/ksk/price-list/${id}/update`,
				method: "post",
				data,
			})
				.then(res => res.data)
				.catch(e => {
					throw e;
				});
		},

		pricelist_qr() {
			return instance({
				url: "/v2/ksk/qr/site",
				method: "get",
				responseType: "arraybuffer",
			})
				.then(res => {
					const dec = new TextDecoder();
					const decoded = dec.decode(res.data);
					if (decoded.includes("PNG")) {
						return window.URL.createObjectURL(new Blob([res.data]));
					}
					const data = JSON.parse(decoded);
					if (data.error) {
						throw { message: data.error };
					}
				})
				.catch(err => {
					throw err;
				});
		},
	};
}
