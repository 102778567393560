export default function(instance) {
	return {
		// ? NOTIFICATIONS (УВЕДОМЛЕНИЯ)

		get_notifications(params) {
			return instance({
				url: "/v2/dispatcher/notification",
				method: "get",
				params,
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},

	};
}
