import Cookies from "js-cookie";
import CryptoJS from "crypto-js";

const TOKEN_KEY = "Authorization";
const REFRESH_KEY = "Refresh";
const SECRET_KEY = "SgE#gA!LEssa";

export default {
  async get_token() {
    const encryptedToken = Cookies.get(TOKEN_KEY);
    if (encryptedToken) {
      const bytes = CryptoJS.AES.decrypt(encryptedToken, SECRET_KEY);
      const originalToken = bytes.toString(CryptoJS.enc.Utf8);
      return originalToken;
    } else {
      return null;
    }
  },
  get_refresh_token() {
    return Cookies.get(REFRESH_KEY);
  },
  async set_token(token) {
    const encryptedToken = CryptoJS.AES.encrypt(token, SECRET_KEY).toString();
    Cookies.set(TOKEN_KEY, encryptedToken);
  },
  set_refresh_token(token) {
    Cookies.set(REFRESH_KEY, token);
  },
  remove_token() {
    Cookies.remove(TOKEN_KEY);
  },
  remove_refresh_token() {
    Cookies.remove(REFRESH_KEY);
  },
};
