import store from "../store";
// * Замена казахских букв на русские
const kazToRus = kazWord => {
	const letters_map = {
		"ә": "а",
		"ғ": "г",
		"қ": "к",
		"ң": "н",
		"ө": "о",
		"ұ": "у",
		"ү": "у",
		"һ": "х",
		"і": "и",
	};

	let rusWord = "";

	for (let i = 0; i < kazWord.length; i++) {
		const letter = kazWord[i].toLowerCase();

		if (letters_map[letter]) {
			rusWord += letters_map[letter];
			continue;
		}

		rusWord += letter;
	}

	return rusWord;
};

// * Получение ФИО из объекта
const getFullname = (obj, returnEmpty = false, showPatronomyc = false) => {
  if (!obj) {
    return returnEmpty ? '' : 'ФИО не указано';
  }
	const { name, surname, patronymic } = obj;
	let  fio = `${surname || ''} ${name || ''}`.trim();
	
	if (showPatronomyc && patronymic) {
    fio = `${fio} ${patronymic}`;
  }

  const maxLength = 30;
  if (fio.length > maxLength) {
    return fio.slice(0, maxLength) + '...';
  }

  return fio;
};

// * Таймстамп в dd/mm/yyyy
const timestampToDdmmyyyy = (timestamp, options = { showTime: false }) => {
	let date = new Date(timestamp * 1000);

	if (!timestamp) {
		date = new Date(Date.now());
	}

	let dd = date.getDate();
	let mm = date.getMonth() + 1;
	let yyyy = date.getFullYear();

	if (dd < 10) dd = "0" + dd;
	if (mm < 10) mm = "0" + mm;

	let result = `${dd}.${mm}.${yyyy}`;

	if (options.showTime) {
		let hh = date.getHours();
		let min = date.getMinutes();
		if (hh < 10) hh = "0" + hh;
		if (min < 10) min = "0" + min;
		result += ` ${hh}:${min}`;
	}

	return result;
};

const getCatalogValue = (value, field, property = "id") => {
	if (!value) {
		return "---";
	}
	const catalog = store.getters["catalog/GET_CATALOG"];
	const type = catalog[field].find(e => e[property] === value);
	return type?.name || "";
};

const isBooleanProp = val => {
	return (
		typeof val === "boolean" ||
		(typeof val === "string" && (val == "false" || !val || val == "true"))
	);
};

export {
	getFullname,
	getCatalogValue,
	isBooleanProp,
	kazToRus,
	timestampToDdmmyyyy,
};
