export default function(instance) {
	return {
		/** Аналитика заявок
		 * @param {*} type {publicity, request, employee, resident}
		 */
		load_analytic({ type, params }) {
			return instance({
				url: `/v2/ksk/analytic/${type}`,
				method: "get",
				params,
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},
	};
}
