<template>
	<v-autocomplete
		v-on="$listeners"
		v-bind="$attrs"
		class="border-all chips-custom autocompl"
		persistent-placeholder
		solo
		hide-details
		flat
		chips
		:filter="addressFilter"
	>
		<template v-if="isSelectAll" v-slot:[`prepend-item`]>
			<v-list-item ripple @mousedown.prevent @click="toggleAll">
				<v-list-item-action>
					<v-icon>{{ chooseAllIcon }}</v-icon>
				</v-list-item-action>
				<v-list-item-content>
					<v-list-item-title>
						<slot name="select-all-title">
							Выбрать все
						</slot>
					</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
			<v-divider></v-divider>
		</template>
	</v-autocomplete>
</template>

<script>
import { isBooleanProp } from "@/helpers/helpers";

export default {
	name: "Autocomplete",

	props: {
		selectAll: {
			type: [String, Boolean],
			default: false,
			validator: isBooleanProp,
		},
	},

	computed: {
		value() {
			return this.$attrs.value;
		},

		items() {
			return this.$attrs.items;
		},

		isSelectAll() {
			return this.selectAll == "true" || this.selectAll === "";
		},

		isChosenAll() {
			return this.value.length === this.items.length;
		},
		isChosenSome() {
			return this.value.length > 0 && !this.isChosenAll;
		},
		chooseAllIcon() {
			if (this.isChosenAll) return "mdi-close-box";
			if (this.isChosenSome) return "mdi-minus-box";
			return "mdi-checkbox-blank-outline";
		},
	},

	methods: {
		toggleAll() {
			this.$nextTick(() => {
				if (!Array.isArray(this.value)) return;
				if (this.value.length) return this.$emit("input", []);

				let all = this.items.slice();
				const itemValue = this.$attrs["item-value"];
				if (itemValue) all = all.map(e => e[itemValue]);
				this.$emit("input", all);
			});
		},
	},
};
</script>

<style>
.autocompl.v-input--dense:not(.v-text-field--outlined) .v-text-field__prefix, .v-text-field.v-input--dense:not(.v-text-field--outlined) .v-text-field__suffix, .v-text-field.v-input--dense:not(.v-text-field--outlined) input {
	padding: 8px 0 8px !important;
}
</style>
