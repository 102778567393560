<template>
	<div
		class="alert-text subtitle-2"
		:class="[`${cType}--text`, { 'font-weight-light': !bold }]"
	>
		<slot>
			{{ text }}
		</slot>
	</div>
</template>

<script>
export default {
	name: "AlertText",

	props: {
		text: {
			type: String,
			default: "Ошибка. Попробуйте повторить действие еще раз",
		},
		type: {
			type: String,
			default: "primary",
		},
		error: {
			type: Boolean,
			default: false,
		},
		bold: {
			type: Boolean,
			default: false,
		},
	},

	computed: {
		cType() {
			return this.error ? "error" : this.type;
		},
	},
};
</script>

<style></style>
