<template>
	<v-dialog v-bind="$attrs" v-on="$listeners" persistent>
		<v-card>
			<v-card-title>
				<slot name="header">
					<span class="subtitle-1 white--text">
						<slot name="title">{{ title }}</slot>
					</span>
					<v-btn color="dark" @click="$emit('input', false)" icon>
						<v-icon color="white">mdi-close</v-icon>
					</v-btn>
				</slot>
			</v-card-title>

			<v-card-text>
				<slot name="body"> </slot>
			</v-card-text>

			<v-card-actions>
				<slot name="footer"> </slot>
			</v-card-actions>
		</v-card>

		<slot name="append"></slot>
	</v-dialog>
</template>

<script>
import { isBooleanProp } from "@/helpers/helpers";

export default {
	name: "Modal",

	props: {
		// value: {
		// 	validator: isBooleanProp,
		// 	required: true,
		// },

		title: {
			type: [String, Number],
			default: "",
		},
	},
};
</script>

<style></style>
