<template>
	<!-- <label v-if="label" class="text-subtitle-2">{{label}}</label> -->
	<v-select
		v-bind="$attrs"
		v-on="$listeners"
		class="border-all"
		:menu-props="{ offsetY: true }"
		dense
		flat
		solo
		hide-details
		:items="items"
		:value="value"
		@change="handleChange"
	>
	</v-select>
</template>

<script>
export default {
	name: "Select",

	props: {
		value: {
			required: true,
		},
		items: {
			type: Array,
			required: false,
		},
	},

	methods: {
		handleChange(e) {
			this.$emit("update:value", e);
			this.$emit("input", e);
			this.$emit("change", e);
		},
	},
};
</script>

<style></style>
