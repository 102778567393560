export default function(instance) {
	return {
		// * Получение списка квартир
		get_house_apartments(id) {
			return instance({
				url: `/rooms?houses_ids[]=${id}`,
				method: "get",
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},
		// * Проверить, есть ли дом в системе EGOV, но не привязан
		check_house_rca(rca) {
			return instance({
				url: `/houses/rca/${rca}`,
				method: "get",
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},

		// * Проверить, есть ли дом в системе, но не привязан
		check_house_bind(params) {
			return instance({
				url: `/v2/house/get/twoGis`,
				method: "get",
				params,
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},
	};
}
