export default function(instance) {
	return {
		// QR Next
		// * Получение групп
		load_groups(params) {
			return instance({
				url: `/qr/groups`,
				method: "get",
        params
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},
		// * Получение группы по id
		load_group(id) {
			return instance({
				url: `/qr/groups/${id}`,
        headers: { "Content-Type": "application/json" },
				method: "get",
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},
		// * Шаблоны qr
		load_qr_templates() {
			return instance({
				url: `/qr/themplates`,
				method: "get",
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},
		// * qr generate
		qr_generate(data) {
			return instance({
				url: `/qr/generate`,
				responseType: 'blob',
				method: "post",
				data
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},
		// * Добавление устройства к группе	
		add_device_to_group(data, group_id) {
			return instance({
				url: `/qr/groups/${group_id}/devices`,
				method: "post",
        data
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},
		// * Получение устройств
		load_devices(params) {
			return instance({
				url: `/qr/devices`,
				method: "get",
        params
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},
		// * Получение устройства
		load_device(id) {
			return instance({
				url: `/qr/devices/${id}`,
				method: "get",
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},
		// * Добавление устройства
		add_device(data) {
			return instance({
				url: `/qr/devices`,
				method: "post",
        data
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},
		// * Удаление устройства
		delete_device(id) {
			return instance({
				url: `/qr/devices/${id}`,
				method: "delete",
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},
		// * Удаление дома устройства
		delete_device_house(device_id, house_id) {
			return instance({
				url: `/qr/devices/${device_id}/houses/${house_id}`,
				method: "delete",
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},
			// * Получение логов
		load_logs(params) {
			return instance({
				url: `/qr/logs`,
				method: "get",
				params
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},
		// * Получение справочника групп
		load_qr_groups(params) {
			return instance({
				url: `/qr/groups/short`,
				method: "get",
				params
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},
		// * Получение статусов логов
		load_logs_statuses(params) {
			return instance({
				url: `/qr/logs/statuses`,
				method: "get",
				params
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},
		// * Получение ошибок логов
		load_logs_errors(params) {
			return instance({
				url: `/qr/logs/errors`,
				method: "get",
				params
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},
  }
}