export default function(instance) {
	return {

		load_posts(params) {
			return instance({
				url: "/v2/administrator/posts",
				method: "get",
				params,
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},

		add_post(data) {
			return instance({
				url: "/v2/administrator/posts/",
				headers: {
					'Content-Type': 'multipart/form-data'
				},
				method: "post",
				data,
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},
		edit_post({ id, data }) {
			return instance({
				url: `/v2/administrator/posts/${id}`,
				method: "post",
				data,
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},
		delete_post(id) {
			return instance({
				url: `/v2/administrator/posts/destroy/${id}`,
				method: "post",
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},
	};
}
