const state = {
  token: '',
  response: {
    data: {
      status: ''
    },
    notification: {
      body: '',
      title: ''
    }
  }
}

const getters = {
  GET_DEVICE_TOKEN: state => state.token,
};

const mutations = {
  setNotification(state, notificationData) {
    state.response = notificationData
  },
  setToken(state, TokenData) {
    state.token = TokenData
  },
  clearNotification(state) {
    state.response = {
      data: {
        status: ''
      },
      notification: {
        body: '',
        title: ''
      }
    }
  }
};

const actions = {
  showNotification({ commit }, notificationData) {
    commit('setNotification', notificationData)
  },
  hideNotification({ commit }) {
    commit('clearNotification')
  },
  saveToken({ commit }, token) {
    commit('setToken', token)
  }
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
