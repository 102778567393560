export default function(instance) {
	return {
		// * Получение всех жителей или сотрудника
		load_users(params) {
			return instance({
				url: `/users`,
				method: "get",
				params,
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},
  }
}